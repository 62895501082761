@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

.timeline {
    margin: 2rem 0;
    padding: 2rem 0;
    color: #2c3e50;
    font-family: 'Montserrat', sans-serif;
  }

  
  .timeline .vertical-timeline-element-content.bounce-in {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .verticalTimelineElement {
    border-radius: 8px !important;

    transition: box-shadow 0.3s !important;

  }
  
  .verticalTimelineElement:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2) !important;
  }
  
  .vertical-timeline-element-title {
    font-weight: 500;
    font-size: 1.25rem;
  }

  
  .jobDescription {
    font-size: 2rem;
    line-height: 1.6;
    color: #ffffffe1;
  }
  
  .iconImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }