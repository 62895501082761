.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    z-index: 5;
    position: relative;
    margin: 5px auto 0;
  }
  
  .icon {
    font-size: 2.25rem;
    transition: color 0.3s ease, box-shadow 0.3s ease;
    z-index: 6;
    color: white;
    padding: 5px; /* Add some padding around the icon */
  }
  
  .icon:hover {
    box-shadow: inset 0 0 0 2px white; /* Apply the glow effect to the actual icon element */
  }
  
  @media (max-width: 768px) {
    .icon {
      font-size: 1.2rem;
    }
  }