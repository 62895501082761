.wavey-footer-canvas {
    position: relative;
    pointer-events: none;
    width: 100%;
    height: 100%; /* Adjust the height according to your needs */
    z-index: 9998;
  }
  
  .wavey-footer {
    position: relative;
    z-index: 9999;
    font-size: 22px;
    bottom: 0;
    transform: translate(calc(50vw - 50%), -100%);
    color: rgb(255, 255, 255);
    text-align: center;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  